// src/contexts/AuthContext.js

import React, { createContext, useState, useEffect } from 'react';
import api from '../api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom'; // Importar useNavigate

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [redirectPath, setRedirectPath] = useState('/'); // Nueva variable de estado

  const navigate = useNavigate(); // Inicializar navigate

  useEffect(() => {
    // Verificar el estado de autenticación al montar
    const checkAuth = async () => {
      try {
        await api.get('/check_auth');
        setIsAuthenticated(true);
      } catch (error) {
        setIsAuthenticated(false);
      }
    };
    checkAuth();
  }, []);

  const openLoginModal = () => {
    setIsLoginModalOpen(true);
  };

  const closeLoginModal = () => {
    setIsLoginModalOpen(false);
  };

  const triggerLogin = (path = '/') => { // Acepta una ruta como parámetro
    setRedirectPath(path); // Almacena la ruta actual
    openLoginModal();
    toast.info('Por favor, inicia sesión para continuar.');
  };

  const loginSuccess = () => {
    setIsAuthenticated(true);
    setIsLoginModalOpen(false);
    toast.success('¡Inicio de sesión exitoso!');
    navigate(redirectPath); // Redirige a la ruta almacenada
  };

  const logout = async () => {
    try {
      await api.post('/logout');
      setIsAuthenticated(false);
      toast.success('¡Cierre de sesión exitoso!');
    } catch (error) {
      toast.error('Error al cerrar sesión.');
    }
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, triggerLogin, loginSuccess, logout, isLoginModalOpen, closeLoginModal }}>
      {children}
    </AuthContext.Provider>
  );
};
