// src/components/Assistant/VideoPlayer.js

import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography } from '@mui/material';

const VideoPlayer = ({ videoUrl, placeholderImage, isTyping, idExhUrl, onVideoEnd }) => {
  const [isVideoLoading, setIsVideoLoading] = useState(true);
  const [videoError, setVideoError] = useState(false);
  const videoRef = useRef(null);

  const handleLoadedData = () => {
    setIsVideoLoading(false);
    if (!isTyping || videoUrl === idExhUrl) { // Play if not typing or it's the IDExh video
      videoRef.current.play().catch((error) => {
        console.error('Error playing video:', error);
      });
    }
  };

  const handleError = () => {
    setIsVideoLoading(false);
    setVideoError(true);
  };

  // Control video playback based on isTyping and videoUrl
  useEffect(() => {
    if (videoRef.current) {
      if (videoUrl === idExhUrl) {
        videoRef.current.loop = true;
        videoRef.current.play().catch((error) => {
          console.error('Error playing IDExh video:', error);
        });
      } else {
        videoRef.current.loop = false;
        if (isTyping) {
          // Pause the video when typing starts
          videoRef.current.pause();
        } else {
          videoRef.current.play().catch((error) => {
            console.error('Error playing video:', error);
          });
        }
      }
    }
  }, [isTyping, videoUrl, idExhUrl]);

  const handleVideoEnded = () => {
    if (videoUrl !== idExhUrl && onVideoEnd) {
      onVideoEnd();
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        overflow: 'hidden',
        position: 'relative',
        zIndex: 2,
      }}
    >
      {isVideoLoading && (
        <Box className="loader" sx={{ alignSelf: 'center', marginTop: '50px' }}></Box>
      )}
      {videoUrl && !videoError && (
        <video
          ref={videoRef}
          src={videoUrl}
          playsInline
          onLoadedData={handleLoadedData}
          onError={handleError}
          onEnded={handleVideoEnded}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            display: isVideoLoading ? 'none' : 'block',
          }}
        >
          Tu navegador no soporta el elemento de video.
        </video>
      )}
      {videoError && (
        <Typography color="error" align="center">
          Error al cargar el video.
        </Typography>
      )}
      {!videoUrl && !isVideoLoading && (
        <Box sx={{ textAlign: 'center' }}>
          <img
            src={placeholderImage}
            alt="Placeholder de Video"
            style={{ width: '100px', height: '100px', marginBottom: '10px' }}
          />
          <Typography variant="body2" color="#666666">
            El asistente está listo para ayudarte.
          </Typography>
        </Box>
      )}
    </Box>
  );
};



export default VideoPlayer;
