// src/components/Assistant/Conversations.js

import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  CircularProgress,
  useMediaQuery,
  Divider,
  Container,
  Button,
  IconButton,
  TextField,
  Snackbar,
  Alert,
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import SidebarCreateAsistente from '../Admin/SidebarCreateAsistente';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import CommentIcon from '@mui/icons-material/Comment';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';

// Custom styles for messages
const MessageContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '100%',
}));

// Soft colors for roles
const userBgColor = '#d0f8ce';       // light green
const assistantBgColor = '#dcecfb';  // light blue

const UserMessage = styled(Paper)(({ theme }) => ({
  backgroundColor: userBgColor,
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  maxWidth: '75%',
  alignSelf: 'flex-end', // User on the right
  margin: theme.spacing(1, 0),
  position: 'relative',
}));

const AssistantMessage = styled(Paper)(({ theme }) => ({
  backgroundColor: assistantBgColor,
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  maxWidth: '75%',
  alignSelf: 'flex-start', // Assistant on the left
  margin: theme.spacing(1, 0),
  position: 'relative',
}));

const Timestamp = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  color: '#666',
  marginTop: theme.spacing(0.5),
}));

const IconContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: theme.spacing(1),
}));

const CommentBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
}));

const Conversations = () => {
  const { assistantId, conversationId } = useParams();
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const navigate = useNavigate();

  // State to track which message is being commented on
  const [activeComment, setActiveComment] = useState(null);
  const [commentText, setCommentText] = useState('');

  // State to track user reactions
  const [userReactions, setUserReactions] = useState({}); // { [messageId]: 'like' | 'dislike' }

  // State for Snackbar notifications
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  useEffect(() => {
    const fetchConversation = async () => {
      try {
        // Mock fetching conversation data
        // Replace this with your actual data fetching logic
        const response = await mockFetchConversation();
        if (response.status === 200) {
          const { metadata } = response.data;
          let convoMessages = metadata || [];

          // Initialize likes, dislikes, and adminComments if they don't exist
          convoMessages = convoMessages.map(msg => ({
            ...msg,
            likes: msg.likes || 0,
            dislikes: msg.dislikes || 0,
            adminComments: msg.adminComments || [],
          }));

          setMessages(convoMessages);

          // Initialize userReactions based on existing data (if available)
          // For this mock, we'll assume no initial reactions
          const reactions = {};
          convoMessages.forEach(msg => {
            if (msg.userReaction === 'like' || msg.userReaction === 'dislike') {
              reactions[msg.id] = msg.userReaction;
            }
          });
          setUserReactions(reactions);
        } else {
          console.error('Error al obtener la conversación');
          setSnackbar({ open: true, message: 'Error al obtener la conversación', severity: 'error' });
        }
      } catch (error) {
        console.error('Error al obtener la conversación:', error);
        setSnackbar({ open: true, message: 'Error al obtener la conversación', severity: 'error' });
      } finally {
        setLoading(false);
      }
    };

    fetchConversation();
  }, [assistantId, conversationId]);

  // Mock function to simulate fetching conversation data
  const mockFetchConversation = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          status: 200,
          data: {
            metadata: [
              {
                id: '1',
                role: 'user',
                content: 'Hola, ¿cómo estás?',
                created_at: Math.floor(Date.now() / 1000) - 3600,
              },
              {
                id: '2',
                role: 'assistant',
                content: '¡Hola! Estoy bien, gracias por preguntar. ¿En qué puedo ayudarte hoy?',
                created_at: Math.floor(Date.now() / 1000) - 3500,
              },
              // Add more mock messages as needed
            ],
          },
        });
      }, 1000); // Simulate network delay
    });
  };

  // Handler for like/dislike
  const handleReaction = (messageId, type) => {
    const currentReaction = userReactions[messageId];

    // Determine the new reaction state
    let newReaction;
    if (currentReaction === type) {
      newReaction = null; // Toggle off
    } else {
      newReaction = type; // Set new reaction
    }

    console.log(`User clicked ${type} on message ${messageId}. Current reaction: ${currentReaction}. New reaction: ${newReaction}`);

    // Update userReactions state
    setUserReactions(prevReactions => {
      const updatedReactions = { ...prevReactions };
      if (newReaction === null) {
        delete updatedReactions[messageId];
      } else {
        updatedReactions[messageId] = newReaction;
      }
      return updatedReactions;
    });

    // Update messages state
    setMessages(prevMessages =>
      prevMessages.map(msg => {
        if (msg.id === messageId) {
          let updatedMsg = { ...msg };
          if (newReaction === 'like') {
            if (currentReaction === 'dislike') {
              updatedMsg.likes += 1;
              updatedMsg.dislikes = Math.max(updatedMsg.dislikes - 1, 0);
            } else {
              updatedMsg.likes += 1;
            }
          } else if (newReaction === 'dislike') {
            if (currentReaction === 'like') {
              updatedMsg.dislikes += 1;
              updatedMsg.likes = Math.max(updatedMsg.likes - 1, 0);
            } else {
              updatedMsg.dislikes += 1;
            }
          } else if (newReaction === null) {
            if (currentReaction === 'like') {
              updatedMsg.likes = Math.max(updatedMsg.likes - 1, 0);
            } else if (currentReaction === 'dislike') {
              updatedMsg.dislikes = Math.max(updatedMsg.dislikes - 1, 0);
            }
          }
          return updatedMsg;
        }
        return msg;
      })
    );

    // Show success snackbar
    setSnackbar({ open: true, message: 'Reacción actualizada correctamente', severity: 'success' });
  };

  // Handler to open comment box
  const handleOpenComment = (messageId) => {
    setActiveComment(messageId);
    setCommentText('');
  };

  // Handler to close comment box
  const handleCloseComment = () => {
    setActiveComment(null);
    setCommentText('');
  };

  // Handler to submit comment
  const handleSubmitComment = (messageId) => {
    if (commentText.trim() === '') {
      setSnackbar({ open: true, message: 'El comentario no puede estar vacío', severity: 'warning' });
      return;
    }

    setMessages(prevMessages =>
      prevMessages.map(msg => {
        if (msg.id === messageId) {
          // Limit to one comment per message
          return {
            ...msg,
            adminComments: [
              {
                comment: commentText,
                date: new Date().toISOString(),
              },
            ],
          };
        }
        return msg;
      })
    );

    // Close comment box
    handleCloseComment();

    // Show success snackbar
    setSnackbar({ open: true, message: 'Comentario agregado correctamente', severity: 'success' });
  };

  // Handler to close Snackbar
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  if (loading) {
    return (
      <Box
        className="loading-container"
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="60vh"
      >
        <CircularProgress aria-label="Cargando conversación" />
      </Box>
    );
  }

  return (
    <Box className="main-wrapper" sx={{ overflowY: 'auto', maxHeight: '100vh' }}>
      <SidebarCreateAsistente />
      <Box component="main" className="main-content" sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
        <Container maxWidth="lg">
          <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
            <Typography variant={isSmallScreen ? "h5" : "h4"} gutterBottom tabIndex={0}>
              Historial de Conversación #{conversationId}
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<ArrowBackIosNewIcon />}
              onClick={() => navigate(`/assistant/${assistantId}/details`)}
              aria-label="Volver a detalles del asistente"
            >
              Volver
            </Button>
          </Box>
          <Divider sx={{ mb: 2 }} />
          {messages && messages.length > 0 ? (
            <Box
              sx={{
                maxHeight: '70vh',
                overflowY: 'auto',
                pr: 1,
              }}
            >
              {messages.map((msg) => (
                <MessageContainer
                  key={msg.id}
                  sx={{
                    alignItems: msg.role === 'user' ? 'flex-end' : 'flex-start'
                  }}
                >
                  {msg.role === 'user' ? (
                    <UserMessage elevation={1}>
                      <Typography variant="body1" sx={{ fontSize: '1rem', lineHeight: 1.5 }}>
                        👤 {msg.content}
                      </Typography>
                      {msg.created_at && (
                        <Timestamp align="right">{new Date(msg.created_at * 1000).toLocaleString()}</Timestamp>
                      )}
                      {/* Reaction Icons */}
                      <IconContainer>
                        <IconButton
                          aria-label="Like"
                          onClick={() => handleReaction(msg.id, 'like')}
                          size="small"
                          color={userReactions[msg.id] === 'like' ? 'primary' : 'default'}
                        >
                          <ThumbUpIcon fontSize="small" />
                        </IconButton>
                        <Typography variant="caption" sx={{ mr: 1 }}>
                          {msg.likes}
                        </Typography>
                        <IconButton
                          aria-label="Dislike"
                          onClick={() => handleReaction(msg.id, 'dislike')}
                          size="small"
                          color={userReactions[msg.id] === 'dislike' ? 'error' : 'default'}
                        >
                          <ThumbDownIcon fontSize="small" />
                        </IconButton>
                        <Typography variant="caption" sx={{ mr: 1 }}>
                          {msg.dislikes}
                        </Typography>
                        <IconButton
                          aria-label="Add Comment"
                          onClick={() => handleOpenComment(msg.id)}
                          size="small"
                        >
                          <CommentIcon fontSize="small" />
                        </IconButton>
                      </IconContainer>
                      {/* Comment Box */}
                      {activeComment === msg.id && (
                        <CommentBox>
                          <TextField
                            variant="outlined"
                            size="small"
                            placeholder="Agregar comentario"
                            value={commentText}
                            onChange={(e) => setCommentText(e.target.value)}
                            fullWidth
                            multiline
                            rows={2}
                            aria-label="Agregar comentario"
                          />
                          <IconButton
                            color="primary"
                            onClick={() => handleSubmitComment(msg.id)}
                            aria-label="Enviar comentario"
                          >
                            <SendIcon />
                          </IconButton>
                          <IconButton
                            color="secondary"
                            onClick={handleCloseComment}
                            aria-label="Cerrar comentario"
                          >
                            <CloseIcon />
                          </IconButton>
                        </CommentBox>
                      )}
                      {/* Display Admin Comments */}
                      {msg.adminComments && msg.adminComments.length > 0 && (
                        <Box mt={1}>
                          {msg.adminComments.map((comment, index) => (
                            <Paper
                              key={index}
                              variant="outlined"
                              sx={{ p: 1, backgroundColor: '#f0f0f0', mb: 0.5 }}
                            >
                              <Typography variant="caption" color="textSecondary">
                                Admin: {new Date(comment.date).toLocaleString()}
                              </Typography>
                              <Typography variant="body2">{comment.comment}</Typography>
                            </Paper>
                          ))}
                        </Box>
                      )}
                    </UserMessage>
                  ) : (
                    <AssistantMessage elevation={1}>
                      <Typography variant="body1" sx={{ fontSize: '1rem', lineHeight: 1.5 }}>
                        🤖 {msg.content}
                      </Typography>
                      {msg.created_at && (
                        <Timestamp align="left">{new Date(msg.created_at * 1000).toLocaleString()}</Timestamp>
                      )}
                      {/* Reaction Icons */}
                      <IconContainer>
                        <IconButton
                          aria-label="Like"
                          onClick={() => handleReaction(msg.id, 'like')}
                          size="small"
                          color={userReactions[msg.id] === 'like' ? 'primary' : 'default'}
                        >
                          <ThumbUpIcon fontSize="small" />
                        </IconButton>
                        <Typography variant="caption" sx={{ mr: 1 }}>
                          {msg.likes}
                        </Typography>
                        <IconButton
                          aria-label="Dislike"
                          onClick={() => handleReaction(msg.id, 'dislike')}
                          size="small"
                          color={userReactions[msg.id] === 'dislike' ? 'error' : 'default'}
                        >
                          <ThumbDownIcon fontSize="small" />
                        </IconButton>
                        <Typography variant="caption" sx={{ mr: 1 }}>
                          {msg.dislikes}
                        </Typography>
                        <IconButton
                          aria-label="Add Comment"
                          onClick={() => handleOpenComment(msg.id)}
                          size="small"
                        >
                          <CommentIcon fontSize="small" />
                        </IconButton>
                      </IconContainer>
                      {/* Comment Box */}
                      {activeComment === msg.id && (
                        <CommentBox>
                          <TextField
                            variant="outlined"
                            size="small"
                            placeholder="Agregar comentario"
                            value={commentText}
                            onChange={(e) => setCommentText(e.target.value)}
                            fullWidth
                            multiline
                            rows={2}
                            aria-label="Agregar comentario"
                          />
                          <IconButton
                            color="primary"
                            onClick={() => handleSubmitComment(msg.id)}
                            aria-label="Enviar comentario"
                          >
                            <SendIcon />
                          </IconButton>
                          <IconButton
                            color="secondary"
                            onClick={handleCloseComment}
                            aria-label="Cerrar comentario"
                          >
                            <CloseIcon />
                          </IconButton>
                        </CommentBox>
                      )}
                      {/* Display Admin Comments */}
                      {msg.adminComments && msg.adminComments.length > 0 && (
                        <Box mt={1}>
                          {msg.adminComments.map((comment, index) => (
                            <Paper
                              key={index}
                              variant="outlined"
                              sx={{ p: 1, backgroundColor: '#f0f0f0', mb: 0.5 }}
                            >
                              <Typography variant="caption" color="textSecondary">
                                Admin: {new Date(comment.date).toLocaleString()}
                              </Typography>
                              <Typography variant="body2">{comment.comment}</Typography>
                            </Paper>
                          ))}
                        </Box>
                      )}
                    </AssistantMessage>
                  )}
                </MessageContainer>
              ))}
            </Box>
          ) : (
            <Typography variant="h6">No hay mensajes en esta conversación.</Typography>
          )}
        </Container>
      </Box>
      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Conversations;