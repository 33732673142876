// src/components/Assistant/MisAsistentes.js

import React, { useEffect, useState, memo, useCallback } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Grid,
  Card,
  CardContent,
  Avatar,
  CircularProgress,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Container,
  Tooltip,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Skeleton,
  Button
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import api from '../../api';
import { toast } from 'react-toastify';
import SidebarCreateAsistente from '../Admin/SidebarCreateAsistente';
import { format } from 'date-fns';
import './MisAsistentes.css';
import PublicIcon from '@mui/icons-material/Public';
import LockIcon from '@mui/icons-material/Lock';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CategoryIcon from '@mui/icons-material/Category';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { debounce } from 'lodash';

const ActiveBadge = styled('span')(({ theme }) => ({
  width: '8px',
  height: '8px',
  borderRadius: '50%',
  backgroundColor: '#4caf50',
  display: 'inline-block',
  marginLeft: theme.spacing(1),
}));

const ConfirmationDialog = ({ open, title, content, onConfirm, onCancel }) => (
  <Dialog
    open={open}
    onClose={onCancel}
    aria-labelledby="confirmation-dialog-title"
    aria-describedby="confirmation-dialog-description"
  >
    <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
    <DialogContent>
      <DialogContentText id="confirmation-dialog-description">
        {content}
        <br/><strong>Esta acción es irreversible.</strong>
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel} size="small" variant="outlined">
        Cancelar
      </Button>
      <Button onClick={onConfirm} size="small" variant="contained" color="error">
        Confirmar
      </Button>
    </DialogActions>
  </Dialog>
);

const generatePastelColor = (typeId) => {
  const colors = [
    '#AEDFF7','#F7E6AD','#F7ADAD','#ADF7BD','#F7C0AD',
    '#D1ADF7','#F7D1AD','#ADF7F0','#F7ADF7','#ADF7F7',
  ];
  return colors[typeId % colors.length];
};

const getCategoryChip = (typeId, categoryName) => (
  <Chip
    icon={<CategoryIcon />}
    label={categoryName}
    size="small"
    variant="outlined"
    style={{
      backgroundColor: generatePastelColor(typeId),
      color: '#333333',
      fontWeight: 500,
      fontSize: '0.75rem',
    }}
    className="category-chip"
  />
);

const getMetricColor = (type, value) => {
  if (type === 'likes' && value > 10) return '#4caf50';
  if (type === 'conversations' && value > 5) return '#2196f3';
  return '#757575';
};

const AsistenteCard = memo(({ assistant, handleEdit, handleDelete, handleDetails, userType }) => {
  const navigate = useNavigate();

  const getAssistantTypeIcon = (publico) => {
    switch (publico) {
      case 0: return <LockIcon fontSize="small" color="action" />;
      case 1: return <PublicIcon fontSize="small" color="action" />;
      case 2: return <VisibilityOffIcon fontSize="small" color="action" />;
      default: return null;
    }
  };

  const isRecentlyActive = (lastActiveDate) => {
    if (!lastActiveDate) return false;
    const now = new Date();
    const lastActive = new Date(lastActiveDate);
    const diffInDays = (now - lastActive) / (1000 * 60 * 60 * 24);
    return diffInDays <= 7;
  };

  return (
    <Grid item xs={12} sm={6} md={12}>
      <Card className="assistant-card" sx={{
        transition: 'box-shadow 0.3s',
        '&:hover': { boxShadow: 4 },
        borderLeft: assistant.Publico === 1 ? '4px solid #4caf50' : (assistant.Publico === 0 ? '4px solid #ff9800' : '4px solid #9e9e9e')
      }}>
        <CardContent className="card-content">
          <Box className="top-section">
            <Avatar
              src={assistant.ImageURL}
              alt={`Avatar de ${assistant.Name}`}
              className="assistant-avatar"
              aria-label={`Avatar de ${assistant.Name}`}
              loading="lazy"
            >
              {assistant.ImageURL ? null : assistant.Name?.charAt(0).toUpperCase()}
            </Avatar>

            <Box className="role-tag">
              {getCategoryChip(assistant.TypeID, assistant.TypeName)}
            </Box>

            <Tooltip
              title={
                assistant.Publico === 0
                  ? 'Privado'
                  : assistant.Publico === 1
                  ? 'Público'
                  : 'Oculto'
              }
              arrow
            >
              <Box className="assistant-type-icon">
                {getAssistantTypeIcon(assistant.Publico)}
              </Box>
            </Tooltip>

            <Typography
              variant="h6"
              component="div"
              className="assistant-name"
              onClick={() => navigate(`/assistant/${assistant.AssistantID}`)}
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
              tabIndex={0}
              onKeyPress={(e) => {
                if (e.key === 'Enter') navigate(`/assistant/${assistant.AssistantID}`);
              }}
              aria-label={`Ver detalles de ${assistant.Name}`}
            >
              {assistant.Name}
              {isRecentlyActive(assistant.CreationDate) && <ActiveBadge />}
            </Typography>

            <Typography
              variant="body2"
              color="text.secondary"
              className="creation-date"
            >
              {assistant.CreationDate
                ? format(new Date(assistant.CreationDate), 'dd/MM/yyyy')
                : 'N/A'}
            </Typography>

            <Box className="action-buttons" sx={{ display: 'flex', gap: 1 }}>
              {userType === 2 && (
                <Tooltip title={`Ver detalles de ${assistant.Name}`} arrow>
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={() => handleDetails(assistant.AssistantID)}
                    aria-label={`Detalles de ${assistant.Name}`}
                  >
                    <InfoIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title={`Editar ${assistant.Name}`} arrow>
                <IconButton
                  size="small"
                  color="success"
                  onClick={() => handleEdit(assistant.AssistantID)}
                  aria-label={`Editar ${assistant.Name}`}
                >
                  <EditIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
              <Tooltip title={`Borrar ${assistant.Name}`} arrow>
                <IconButton
                  size="small"
                  color="error"
                  onClick={() => handleDelete(assistant.AssistantID, assistant.Name)}
                  aria-label={`Borrar ${assistant.Name}`}
                >
                  <DeleteIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>

          {assistant.Description && (
            <Box className="description-container" sx={{ marginTop: 1 }}>
              <Typography
                variant="body2"
                color="text.primary"
                className="assistant-description"
              >
                {assistant.Description}
              </Typography>
            </Box>
          )}

          <Box className="details-container" sx={{ marginTop: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} className="metric-item">
                <Box className="metric-box" style={{ color: getMetricColor('conversations', assistant.num_conversations) }}>
                  <ChatBubbleOutlineIcon fontSize="small" aria-label="Conversaciones" />
                  <Tooltip title="Número de conversaciones con usuarios" arrow>
                    <Typography variant="body2">
                      {assistant.num_conversations}
                    </Typography>
                  </Tooltip>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4} className="metric-item">
                <Box className="metric-box" style={{ color: getMetricColor('likes', assistant.num_likes) }}>
                  <ThumbUpAltOutlinedIcon fontSize="small" aria-label="Likes" />
                  <Tooltip title="Veces que este asistente ha recibido un 'Like'" arrow>
                    <Typography variant="body2">
                      {assistant.num_likes}
                    </Typography>
                  </Tooltip>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4} className="metric-item">
                <Box className="metric-box" style={{ color: getMetricColor('favorites', assistant.num_favorites) }}>
                  <FavoriteIcon fontSize="small" aria-label="Favoritos" />
                  <Tooltip title="Número de usuarios que han marcado este asistente como favorito" arrow>
                    <Typography variant="body2">
                      {assistant.num_favorites}
                    </Typography>
                  </Tooltip>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
});

const MisAsistentes = () => {
  const [assistants, setAssistants] = useState([]);
  const [filteredAssistants, setFilteredAssistants] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortOption, setSortOption] = useState('date-newest');
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    assistantId: null,
    assistantName: '',
  });
  const navigate = useNavigate();
  const [userType, setUserType] = useState(null);

  const handleDetails = useCallback(
    (assistantId) => {
      navigate(`/assistant/${assistantId}/details`);
    },
    [navigate]
  );

  const handleConfirmDelete = () => {
    const { assistantId, assistantName } = confirmDialog;
    deleteAssistant(assistantId, assistantName);
    setConfirmDialog({ open: false, assistantId: null, assistantName: '' });
  };

  const handleCancelDelete = () => {
    setConfirmDialog({ open: false, assistantId: null, assistantName: '' });
  };

  const deleteAssistant = async (assistantId, assistantName) => {
    try {
      const response = await api.put(`/admin/asistentes/delete/${assistantId}`);
      if (response.status === 200) {
        toast.success(`Asistente "${assistantName}" eliminado exitosamente`);
        setAssistants((prev) =>
          prev.filter((assistant) => assistant.AssistantID !== assistantId)
        );
      } else {
        toast.error('Error al eliminar el asistente');
      }
    } catch (error) {
      console.error('Error al eliminar el asistente:', error);
      toast.error('Error al eliminar el asistente');
    }
  };

  const handleDeleteAssistantClick = (assistantId, assistantName) => {
    setConfirmDialog({ open: true, assistantId, assistantName });
  };

  const handleEditAssistant = useCallback((assistantId) => {
    navigate(`/editassistant/${assistantId}`);
  }, [navigate]);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await api.get('/user/info');
        if (response.status === 200) {
          setUserType(response.data.UserTypeID);
        }
      } catch (error) {
        console.error('Error al obtener información del usuario:', error);
      }
    };
    fetchUserInfo();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [assistantsResponse, categoriesResponse] = await Promise.all([
          api.get('/myassistants_with_stats'),
          api.get('/asistentes/categories'),
        ]);

        if (assistantsResponse.status === 200) {
          setAssistants(assistantsResponse.data.assistants);
          setFilteredAssistants(assistantsResponse.data.assistants);
        }

        if (categoriesResponse.status === 200) {
          setCategories(categoriesResponse.data.categories);
        }
      } catch (error) {
        console.error('Error al obtener datos:', error);
        toast.error('Error al obtener los datos');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const debouncedSearch = useCallback(
    debounce((query) => {
      setSearchQuery(query);
    }, 300),
    []
  );

  const handleSearchChange = (event) => {
    debouncedSearch(event.target.value);
  };

  const handleSortChange = (event) => {
    setSortOption(event.target.value);
  };

  const handleCategoryChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedCategory(selectedValue === '' ? '' : Number(selectedValue));
  };

  useEffect(() => {
    let updatedAssistants = [...assistants];

    if (searchQuery.trim() !== '') {
      updatedAssistants = updatedAssistants.filter((assistant) =>
        assistant.Name.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    if (selectedCategory !== '') {
      updatedAssistants = updatedAssistants.filter(
        (assistant) => assistant.TypeID === selectedCategory
      );
    }

    switch (sortOption) {
      case 'name-asc':
        updatedAssistants.sort((a, b) => a.Name.localeCompare(b.Name));
        break;
      case 'name-desc':
        updatedAssistants.sort((a, b) => b.Name.localeCompare(a.Name));
        break;
      case 'date-newest':
        updatedAssistants.sort(
          (a, b) => new Date(b.CreationDate) - new Date(a.CreationDate)
        );
        break;
      case 'date-oldest':
        updatedAssistants.sort(
          (a, b) => new Date(a.CreationDate) - new Date(b.CreationDate)
        );
        break;
      case 'favorites-desc':
        updatedAssistants.sort((a, b) => b.num_favorites - a.num_favorites);
        break;
      case 'likes-desc':
        updatedAssistants.sort((a, b) => b.num_likes - a.num_likes);
        break;
      case 'conversations-desc':
        updatedAssistants.sort(
          (a, b) => b.num_conversations - a.num_conversations
        );
        break;
      default:
        // sin orden
        break;
    }

    setFilteredAssistants(updatedAssistants);
  }, [assistants, sortOption, searchQuery, selectedCategory]);

  if (loading) {
    return (
      <Box className="loading-container" role="status" aria-live="polite" sx={{ p: 4 }}>
        {/* Skeleton en lugar de Spinner */}
        <Container maxWidth="lg">
          <Typography variant="h5" gutterBottom>
            Cargando Asistentes...
          </Typography>
          <Grid container spacing={4}>
            {Array.from(new Array(3)).map((_, index) => (
              <Grid item xs={12} sm={6} md={12} key={index}>
                <Skeleton variant="rectangular" height={80} />
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    );
  }

  return (
    <Box className="main-wrapper">
      <SidebarCreateAsistente />

      <Box component="main" className="main-content" sx={{ p: 2 }}>
        <Container maxWidth="lg">
          <Box className="header-container" display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Box>
              <Typography variant="h4" gutterBottom className="title">
                Mis Asistentes ({filteredAssistants.length})
              </Typography>
            </Box>
            <Tooltip title="Agregar Nuevo Asistente" arrow>
              <IconButton
                color="primary"
                onClick={() => navigate('/createavatar')}
                aria-label="Agregar Nuevo Asistente"
              >
                <AddCircleOutlineIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          </Box>

          <Box className="filters-container" mb={3}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Buscar Asistente"
                  variant="outlined"
                  onChange={handleSearchChange}
                  fullWidth
                  className="search-bar"
                  aria-label="Buscar Asistente"
                  InputProps={{
                    startAdornment: <PublicIcon />,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <FormControl variant="outlined" className="sort-control" fullWidth>
                  <InputLabel id="sort-label">Ordenar Por</InputLabel>
                  <Select
                    labelId="sort-label"
                    value={sortOption}
                    onChange={handleSortChange}
                    label="Ordenar Por"
                    aria-label="Ordenar Por"
                  >
                    <MenuItem value="date-newest">Fecha Creación (Reciente)</MenuItem>
                    <MenuItem value="date-oldest">Fecha Creación (Antiguo)</MenuItem>
                    <MenuItem value="name-asc">Nombre (A-Z)</MenuItem>
                    <MenuItem value="name-desc">Nombre (Z-A)</MenuItem>
                    <MenuItem value="favorites-desc">Favoritos (Más)</MenuItem>
                    <MenuItem value="likes-desc">Likes (Más)</MenuItem>
                    <MenuItem value="conversations-desc">Conversaciones (Más)</MenuItem>
                    <MenuItem value="">
                      <em>Sin Orden</em>
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <FormControl variant="outlined" className="category-filter" fullWidth>
                  <InputLabel id="category-label">Categoría</InputLabel>
                  <Select
                    labelId="category-label"
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                    label="Categoría"
                    aria-label="Filtrar por Categoría"
                  >
                    <MenuItem value="">
                      <em>Todas</em>
                    </MenuItem>
                    {categories.map((category) => (
                      <MenuItem key={category.TypeID} value={category.TypeID}>
                        {category.TypeName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>

          <Grid container spacing={4}>
            {filteredAssistants.length > 0 ? (
              filteredAssistants.map((assistant) => (
                <AsistenteCard
                  key={assistant.AssistantID}
                  assistant={assistant}
                  handleEdit={handleEditAssistant}
                  handleDelete={handleDeleteAssistantClick}
                  handleDetails={handleDetails}
                  userType={userType}
                />
              ))
            ) : (
              <Box className="no-results" sx={{ mt: 4 }}>
                <Typography variant="h6" color="text.secondary">
                  No se encontraron asistentes que coincidan con tu búsqueda.
                </Typography>
              </Box>
            )}
          </Grid>
        </Container>
      </Box>

      <ConfirmationDialog
        open={confirmDialog.open}
        title="Confirmar Eliminación"
        content={`¿Estás seguro de que deseas eliminar al asistente "${confirmDialog.assistantName}"? Esta acción no se puede deshacer.`}
        onConfirm={handleConfirmDelete}
        onCancel={handleCancelDelete}
      />
    </Box>
  );
};

export default MisAsistentes;
