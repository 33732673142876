// =======================================
// src/components/LoginModal.js
// =======================================
import React, { useState, useContext } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
  TextField,
  Typography,
  Box,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Divider,
  CircularProgress,
  Select,
  MenuItem,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import api from '../api';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faUser, faEnvelope, faFileUpload } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { AuthContext } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

// Añadir iconos a la librería de Font Awesome
library.add(faLock, faUser, faEnvelope, faFileUpload);

const LoginModal = ({ open, onClose }) => {
  const [currentView, setCurrentView] = useState('login'); // 'login', 'register', 'reset'
  const { loginSuccess } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // Estados para el formulario de inicio de sesión
  const [loginFormData, setLoginFormData] = useState({
    username_or_email: '',
    password: '',
  });
  const [loginFeedback, setLoginFeedback] = useState('');
  const [showPasswordLogin, setShowPasswordLogin] = useState(false);

  // Estados para el formulario de registro
  const [registerFormData, setRegisterFormData] = useState({
    user_type: '', // candidate | hr
    username: '',
    email: '',
    password: '',
    confirm_password: '',
    gdpr_consent: false,
    time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    cv_file: null, // CV
  });
  const [registerFeedback, setRegisterFeedback] = useState('');
  const [showPasswordRegister, setShowPasswordRegister] = useState(false);
  const [showConfirmPasswordRegister, setShowConfirmPasswordRegister] = useState(false);

  // Estados para el formulario de restablecimiento de contraseña
  const [resetFormData, setResetFormData] = useState({
    email: '',
  });
  const [resetFeedback, setResetFeedback] = useState('');
  const [showPasswordReset, setShowPasswordReset] = useState(false);

  // Manejo de cambios en el formulario de inicio de sesión
  const handleLoginChange = (e) => {
    const { name, value } = e.target;
    setLoginFormData({
      ...loginFormData,
      [name]: value,
    });
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    setLoginFeedback('');
    setLoading(true);

    try {
      const response = await api.post('/login', {
        username_or_email: loginFormData.username_or_email,
        password: loginFormData.password,
      });
      const { session_token } = response.data;
      localStorage.setItem('session_token', session_token);
      loginSuccess();
      onClose();
    } catch (error) {
      if (error.response && error.response.data.error) {
        setLoginFeedback(error.response.data.error);
        toast.error(error.response.data.error);
      } else {
        setLoginFeedback('Error al iniciar sesión.');
        toast.error('Error al iniciar sesión.');
      }
    } finally {
      setLoading(false);
    }
  };

  // Manejo de cambios en el formulario de registro
  const handleRegisterChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    if (type === 'checkbox') {
      setRegisterFormData({
        ...registerFormData,
        [name]: checked,
      });
    } else if (type === 'file') {
      setRegisterFormData({
        ...registerFormData,
        cv_file: files[0],
      });
    } else {
      setRegisterFormData({
        ...registerFormData,
        [name]: value,
      });
    }
  };

  const handleRegisterSubmit = async (e) => {
    e.preventDefault();
    setRegisterFeedback('');
    setLoading(true);

    if (registerFormData.password !== registerFormData.confirm_password) {
      setRegisterFeedback('Las contraseñas no coinciden.');
      toast.error('Las contraseñas no coinciden.');
      setLoading(false);
      return;
    }

    try {
      // Armamos el FormData con multipart/form-data
      const formDataToSend = new FormData();
      formDataToSend.append('user_type', registerFormData.user_type);
      formDataToSend.append('username', registerFormData.username);
      formDataToSend.append('email', registerFormData.email);
      formDataToSend.append('password', registerFormData.password);
      formDataToSend.append('gdpr_consent', registerFormData.gdpr_consent);
      formDataToSend.append('time_zone', registerFormData.time_zone);
      if (registerFormData.cv_file) {
        formDataToSend.append('cv_file', registerFormData.cv_file);
      }

      const response = await api.post('/register', formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      toast.success('¡Registro exitoso! Revisa tu correo para confirmar tu cuenta.');

      // Reset form
      setRegisterFormData({
        user_type: '',
        username: '',
        email: '',
        password: '',
        confirm_password: '',
        gdpr_consent: false,
        time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        cv_file: null,
      });
      setCurrentView('login');
    } catch (error) {
      if (error.response && error.response.data.error) {
        setRegisterFeedback(error.response.data.error);
        toast.error(error.response.data.error);
      } else {
        setRegisterFeedback('Error al registrarse.');
        toast.error('Error al registrarse.');
      }
    } finally {
      setLoading(false);
    }
  };

  // Manejo de cambios en el formulario de restablecimiento de contraseña
  const handleResetChange = (e) => {
    const { name, value } = e.target;
    setResetFormData({
      ...resetFormData,
      [name]: value,
    });
  };

  const handleResetSubmit = async (e) => {
    e.preventDefault();
    setResetFeedback('');
    setLoading(true);

    try {
      await api.post('/reset_password', {
        email: resetFormData.email,
      });
      toast.success('¡Correo de restablecimiento enviado! Revisa tu bandeja de entrada.');
      setResetFormData({ email: '' });
      setCurrentView('login');
    } catch (error) {
      if (error.response && error.response.data.error) {
        setResetFeedback(error.response.data.error);
        toast.error(error.response.data.error);
      } else {
        setResetFeedback('Error al enviar el correo de restablecimiento.');
        toast.error('Error al enviar el correo de restablecimiento.');
      }
    } finally {
      setLoading(false);
    }
  };

  const switchView = (view) => {
    setCurrentView(view);
    setLoginFeedback('');
    setRegisterFeedback('');
    setResetFeedback('');
  };

  const handleClickShowPasswordLogin = () => {
    setShowPasswordLogin(!showPasswordLogin);
  };
  const handleClickShowPasswordRegister = () => {
    setShowPasswordRegister(!showPasswordRegister);
  };
  const handleClickShowConfirmPasswordRegister = () => {
    setShowConfirmPasswordRegister(!showConfirmPasswordRegister);
  };
  const handleClickShowPasswordReset = () => {
    setShowPasswordReset(!showPasswordReset);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle sx={{ textAlign: 'center', position: 'relative', paddingBottom: '10px' }}>
        {currentView === 'login' && 'Iniciar Sesión'}
        {currentView === 'register' && 'Registrarse'}
        {currentView === 'reset' && 'Restablecer Contraseña'}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ padding: '20px' }}>
        {currentView === 'login' && (
          <Box>
            <form onSubmit={handleLoginSubmit}>
              <TextField
                label="Correo electrónico o nombre de usuario"
                name="username_or_email"
                value={loginFormData.username_or_email}
                onChange={handleLoginChange}
                fullWidth
                margin="normal"
                required
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FontAwesomeIcon icon="user" color="#9e9e9e" />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label="Contraseña"
                type={showPasswordLogin ? 'text' : 'password'}
                name="password"
                value={loginFormData.password}
                onChange={handleLoginChange}
                fullWidth
                margin="normal"
                required
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FontAwesomeIcon icon="lock" color="#9e9e9e" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowPasswordLogin} edge="end">
                        {showPasswordLogin ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              {loginFeedback && (
                <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                  {loginFeedback}
                </Typography>
              )}

              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{
                  mt: 2,
                  borderRadius: '25px',
                  padding: '10px',
                  textTransform: 'none',
                  fontWeight: 'bold',
                  backgroundColor: '#1976d2',
                  '&:hover': { backgroundColor: '#115293' },
                }}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} color="inherit" /> : 'Iniciar Sesión'}
              </Button>
            </form>

            <Typography variant="body2" align="center" sx={{ mt: 2 }}>
              <Button
                onClick={() => switchView('reset')}
                sx={{ textTransform: 'none', color: '#1976d2' }}
              >
                ¿Olvidaste tu contraseña?
              </Button>
            </Typography>

            <Divider sx={{ my: 3 }}>O</Divider>

            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              ¿No tienes una cuenta?{' '}
              <Button onClick={() => switchView('register')} sx={{ textTransform: 'none', color: '#1976d2' }}>
                Regístrate aquí
              </Button>
            </Typography>
          </Box>
        )}

        {currentView === 'register' && (
          <Box>
            <form onSubmit={handleRegisterSubmit}>
              <Typography variant="subtitle1" sx={{ mt: 1 }}>
                Selecciona el tipo de usuario:
              </Typography>
              <Select
                name="user_type"
                value={registerFormData.user_type}
                onChange={handleRegisterChange}
                displayEmpty
                fullWidth
                required
                sx={{ mt: 1 }}
              >
                <MenuItem value="" disabled>
                  -- Selecciona un tipo de usuario --
                </MenuItem>
                <MenuItem value="candidate">Candidato</MenuItem>
                <MenuItem value="hr">Responsable HR</MenuItem>
              </Select>

              {registerFormData.user_type === 'candidate' && (
                <>
                  <TextField
                    label="Nombre"
                    name="username"
                    value={registerFormData.username}
                    onChange={handleRegisterChange}
                    fullWidth
                    margin="normal"
                    required
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon="user" color="#9e9e9e" />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    label="Correo electrónico"
                    name="email"
                    type="email"
                    value={registerFormData.email}
                    onChange={handleRegisterChange}
                    fullWidth
                    margin="normal"
                    required
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon="envelope" color="#9e9e9e" />
                        </InputAdornment>
                      ),
                    }}
                  />

                  <Button
                    variant="contained"
                    component="label"
                    sx={{ mt: 2, mb: 2, textTransform: 'none' }}
                  >
                    <FontAwesomeIcon icon="file-upload" style={{ marginRight: 8 }} />
                    {registerFormData.cv_file ? registerFormData.cv_file.name : 'Subir CV'}
                    <input
                      type="file"
                      name="cv_file"
                      hidden
                      onChange={handleRegisterChange}
                      accept=".pdf,.doc,.docx"
                    />
                  </Button>
                </>
              )}

              {registerFormData.user_type === 'hr' && (
                <>
                  <TextField
                    label="Nombre"
                    name="username"
                    value={registerFormData.username}
                    onChange={handleRegisterChange}
                    fullWidth
                    margin="normal"
                    required
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon="user" color="#9e9e9e" />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    label="Correo electrónico"
                    name="email"
                    type="email"
                    value={registerFormData.email}
                    onChange={handleRegisterChange}
                    fullWidth
                    margin="normal"
                    required
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon="envelope" color="#9e9e9e" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </>
              )}

              <TextField
                label="Contraseña"
                type={showPasswordRegister ? 'text' : 'password'}
                name="password"
                value={registerFormData.password}
                onChange={handleRegisterChange}
                fullWidth
                margin="normal"
                required
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FontAwesomeIcon icon="lock" color="#9e9e9e" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowPasswordRegister} edge="end">
                        {showPasswordRegister ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label="Confirmar Contraseña"
                type={showConfirmPasswordRegister ? 'text' : 'password'}
                name="confirm_password"
                value={registerFormData.confirm_password}
                onChange={handleRegisterChange}
                fullWidth
                margin="normal"
                required
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FontAwesomeIcon icon="lock" color="#9e9e9e" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowConfirmPasswordRegister} edge="end">
                        {showConfirmPasswordRegister ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={registerFormData.gdpr_consent}
                    onChange={handleRegisterChange}
                    name="gdpr_consent"
                    required
                    color="primary"
                  />
                }
                label={
                  <>
                    Acepto los{' '}
                    <a
                      href="https://www.facemyai.com/privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: '#1976d2' }}
                    >
                      términos y condiciones
                    </a>
                  </>
                }
              />

              {registerFeedback && (
                <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                  {registerFeedback}
                </Typography>
              )}

              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{
                  mt: 2,
                  borderRadius: '25px',
                  padding: '10px',
                  textTransform: 'none',
                  fontWeight: 'bold',
                  backgroundColor: '#1976d2',
                  '&:hover': { backgroundColor: '#115293' },
                }}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} color="inherit" /> : 'Registrarse'}
              </Button>
            </form>

            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              ¿Ya tienes una cuenta?{' '}
              <Button onClick={() => switchView('login')} sx={{ textTransform: 'none', color: '#1976d2' }}>
                Inicia Sesión aquí
              </Button>
            </Typography>
          </Box>
        )}

        {currentView === 'reset' && (
          <Box>
            <form onSubmit={handleResetSubmit}>
              <TextField
                label="Correo electrónico"
                name="email"
                type="email"
                value={resetFormData.email}
                onChange={handleResetChange}
                fullWidth
                margin="normal"
                required
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FontAwesomeIcon icon="envelope" color="#9e9e9e" />
                    </InputAdornment>
                  ),
                }}
              />
              {resetFeedback && (
                <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                  {resetFeedback}
                </Typography>
              )}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{
                  mt: 2,
                  borderRadius: '25px',
                  padding: '10px',
                  textTransform: 'none',
                  fontWeight: 'bold',
                  backgroundColor: '#1976d2',
                  '&:hover': { backgroundColor: '#115293' },
                }}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} color="inherit" /> : 'Enviar Correo de Restablecimiento'}
              </Button>
            </form>

            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              ¿Recuerdas tu contraseña?{' '}
              <Button onClick={() => switchView('login')} sx={{ textTransform: 'none', color: '#1976d2' }}>
                Inicia Sesión aquí
              </Button>
            </Typography>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default LoginModal;
