// src/components/PrivateRoute.js

import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const PrivateRoute = ({ children }) => {
  const { isAuthenticated, triggerLogin } = useContext(AuthContext);
  const location = useLocation();

  if (isAuthenticated === null) {
    // Mostrar spinner mientras se verifica la autenticación
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
        role="status"
        aria-live="polite"
      >
        <CircularProgress aria-label="Verificando autenticación" />
      </Box>
    );
  }

  if (!isAuthenticated) {
    // Activar el modal de inicio de sesión y pasar la ruta actual
    triggerLogin(location.pathname);
    return null;
  }

  return children;
};

export default PrivateRoute;
