// src/components/Assistant/Assistant.js

import React, { useState, useEffect, useRef, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../../api';
import './Assistant.css';
import {
  IconButton,
  Typography,
  CssBaseline,
  Box,
  Snackbar,
  Alert,
  Fade,
  InputAdornment,
  TextField,
  Drawer,
  Toolbar,
} from '@mui/material';
import {
  Mic as MicIcon,
  Send as SendIcon,
  ThumbUp as ThumbUpIcon,
  ThumbDown as ThumbDownIcon,
  Favorite as FavoriteIcon,
  Share as ShareIcon,
  Download as DownloadIcon,
  PhotoCamera as PhotoCameraIcon,
  AttachFile as AttachFileIcon,
} from '@mui/icons-material';
import { toast } from 'react-toastify';
import SidebarCreateAsistente from '../Admin/SidebarCreateAsistente';
import RightSidebar from '../RightSidebar';
import VideoPlayer from './VideoPlayer';

import { AuthContext } from '../../contexts/AuthContext'; // Import AuthContext

const Assistant = () => {
  const { assistantId } = useParams();
  const navigate = useNavigate();

  // Consume AuthContext
  const { isAuthenticated, triggerLogin } = useContext(AuthContext);

  // State variables
  const [assistantName, setAssistantName] = useState('');
  const [IDExh, setIDExh] = useState('');
  const [assistantDescription, setAssistantDescription] = useState('');
  const [chatMessages, setChatMessages] = useState([]);
  const [messageInput, setMessageInput] = useState('');
  const [isMessageInputDisabled, setIsMessageInputDisabled] = useState(!isAuthenticated);
  const [isTyping, setIsTyping] = useState(false);
  const [assistantStatus, setAssistantStatus] = useState('disponible');
  const [errorMessage, setErrorMessage] = useState('');
  const [welcomeMessage, setWelcomeMessage] = useState(true);
  const [initialPrompts, setInitialPrompts] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [isFavorited, setIsFavorited] = useState(false);
  const [isRightSidebarOpen, setIsRightSidebarOpen] = useState(false);
  const [isLeftSidebarOpen, setIsLeftSidebarOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [audioBlob, setAudioBlob] = useState(null);
  const [audioUrl, setAudioUrl] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMicActive, setIsMicActive] = useState(false);

  // Microphone and camera state
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [cameraStream, setCameraStream] = useState(null);

  const chatEndRef = useRef(null);
  const cameraRef = useRef(null);
  const fileInputRef = useRef(null);

  // Placeholder transcription function
  const startTranscription = async (audioBlob) => {
    console.log('Starting transcription...');
    const formData = new FormData();
    formData.append('audio', audioBlob, 'recorded_audio.webm');

    try {
      console.log('About to POST /transcribe_audio...');
      const response = await api.post('/transcribe_audio', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      console.log('Transcription response:', response.data);

      if (response.data && response.data.transcript) {
        const transcript = response.data.transcript.trim();
        console.log('Transcript received:', transcript);
        if (transcript.length > 0) {
          appendMessage('user', transcript);
          setIsMessageInputDisabled(true);
          sendMessageToAssistant(transcript);
        } else {
          toast.error('No se pudo transcribir el audio (transcript vacío).');
        }
      } else {
        toast.error('No se pudo transcribir el audio (sin transcript).');
      }
    } catch (error) {
      console.error('Error transcribing audio:', error);
      toast.error('Error transcribiendo el audio.');
    }
  };

  // Load assistant details
  useEffect(() => {
    const fetchAssistantDetails = async () => {
      try {
        const response = await api.get(`/assistants/${assistantId}`);
        const data = response.data;
        setAssistantName(data.Name);
        setIDExh(data.IDExh);
        setVideoUrl(data.IDExh || 'https://ugc-idle.s3-us-west-2.amazonaws.com/est_5e261b0713e88f46768f629f09f70f7f.mp4');
        setAssistantDescription(data.Description);
        setInitialPrompts(data.InitialPrompt.slice(0, 2));
      } catch (error) {
        console.error('Error fetching assistant details:', error);
        setErrorMessage('Error loading assistant details.');
      }
    };
    fetchAssistantDetails();
  }, [assistantId]);

  // Clear chat when assistant changes and stop camera if on
  useEffect(() => {
    setChatMessages([]);
    if (cameraStream) {
      cameraStream.getTracks().forEach(track => track.stop());
      setCameraStream(null);
    }
  }, [assistantId]);

  useEffect(() => {
    if (cameraRef.current && cameraStream) {
      cameraRef.current.srcObject = cameraStream;
      cameraRef.current.play().catch((err) => console.error('Error playing video:', err));
    }
  }, [cameraStream]);

  // Fetch like/favorite status
  useEffect(() => {
    const fetchInteractionStatus = async () => {
      try {
        const favoriteResponse = await api.get(`/assistants/${assistantId}/favorite-status`);
        if (favoriteResponse.status === 200) {
          setIsFavorited(favoriteResponse.data.isFavorited);
        }

        const likeResponse = await api.get(`/assistants/${assistantId}/like-status`);
        if (likeResponse.status === 200) {
          setIsLiked(likeResponse.data.isLiked);
        }
      } catch (error) {
        console.error('Error fetching interaction status:', error);
      }
    };
    fetchInteractionStatus();
  }, [assistantId]);

  // Hide welcome message
  useEffect(() => {
    const timer = setTimeout(() => {
      setWelcomeMessage(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  // Detect screen size
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Scroll to bottom
  useEffect(() => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chatMessages, isTyping]);

  // Update videoUrl if IDExh changes
  useEffect(() => {
    if (IDExh) {
      setVideoUrl(IDExh);
    } else {
      setVideoUrl('https://ugc-idle.s3-us-west-2.amazonaws.com/est_5e261b0713e88f46768f629f09f70f7f.mp4');
    }
  }, [IDExh]);

  const handleRightSidebarToggle = () => {
    setIsRightSidebarOpen(!isRightSidebarOpen);
  };

  const handleLeftSidebarToggle = () => {
    setIsLeftSidebarOpen(!isLeftSidebarOpen);
  };

  // Messaging
  const sendMessage = () => {
    const message = messageInput.trim();
    if (message !== '') {
      appendMessage('user', message);
      setMessageInput('');
      setIsMessageInputDisabled(true);
      sendMessageToAssistant(message);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') sendMessage();
  };

  const appendMessage = (sender, text, video_url = null) => {
    const newMessage = {
      sender,
      text,
      time: new Date().toLocaleTimeString(),
      video_url,
      likeState: 'none',
    };
    setChatMessages((prev) => [...prev, newMessage]);
  };

  const sendMessageToAssistant = async (message) => {
    setIsTyping(true);
    setIsMessageInputDisabled(true);
    setAssistantStatus('escribiendo');
    try {
      const response = await api.post('/assistant', { message, assistantId });
      const data = response.data;
      if (data.error) {
        appendMessage('assistant', 'Error: ' + data.error);
        toast.error('Error sending the message.');
        setErrorMessage('Error sending the message.');
        setAssistantStatus('disponible');
      } else {
        appendMessage('assistant', data.response, data.video_url || null);
        toast.success('Message received.');
        if (data.video_url) {
          setVideoUrl(data.video_url);
        } else {
          setVideoUrl(IDExh || 'https://ugc-idle.s3-us-west-2.amazonaws.com/est_5e261b0713e88f46768f629f09f70f7f.mp4');
        }
        setAssistantStatus('disponible');
      }
    } catch (error) {
      console.error('Error:', error);
      appendMessage('assistant', 'Error connecting to the server. Please try again.');
      toast.error('Error connecting to the server.');
      setErrorMessage('Error connecting to the server.');
      setAssistantStatus('desconectado');
    } finally {
      setIsTyping(false);
      setIsMessageInputDisabled(false);
    }
  };

  // State for microphone

  const handleVoiceInput = async () => {
    if (isRecording) {
      console.log('Stopping recording...');
      mediaRecorder.stop();
      setIsRecording(false);
      setIsMicActive(false);
    } else {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        console.log('Microphone access granted.');
        const recorder = new MediaRecorder(stream);
        setMediaRecorder(recorder);

        const chunks = [];
        recorder.ondataavailable = (e) => {
          console.log('Data available:', e.data);
          chunks.push(e.data);
        };

        recorder.onstop = async () => {
          console.log('Recording stopped.');
          const blob = new Blob(chunks, { type: 'audio/webm' });
          console.log('Audio blob size:', blob.size);
          await startTranscription(blob);
          stream.getTracks().forEach(track => track.stop());
        };

        recorder.start();
        console.log('Recording started...');
        setIsRecording(true);
        setIsMicActive(true);
        toast.info('Grabando audio, presiona el micrófono nuevamente para detener.');
      } catch (error) {
        console.error('Microphone access error:', error);
        toast.error('No se pudo acceder al micrófono.');
      }
    }
  };

  // Camera
  const handleCameraInput = async () => {
    if (cameraStream) {
      cameraStream.getTracks().forEach((track) => track.stop());
      setCameraStream(null);
      return;
    }
  
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      setCameraStream(stream);
    } catch (error) {
      console.error('Camera access error:', error);
      toast.error('No se pudo acceder a la cámara.');
    }
  };
  
  // Removed unused JSX block here to prevent errors

  const handleCloseSnackbar = () => {
    setErrorMessage('');
  };

  const handleInitialPromptClick = (prompt) => {
    setInitialPrompts([]);
    appendMessage('user', prompt);
    setIsMessageInputDisabled(true);
    sendMessageToAssistant(prompt);
  };

  const handleInputChange = (e) => {
    setMessageInput(e.target.value);
    if (initialPrompts.length > 0) {
      setInitialPrompts([]);
    }
  };

  const handleLike = async () => {
    try {
      const action = isLiked ? 'remove' : 'add';
      const response = await api.post(`/assistants/${assistantId}/like`, { action });
      if (response.status === 200) {
        setIsLiked(!isLiked);
        if (!isLiked) {
          toast.success('¡Gracias por tu apoyo!', { position: 'top-center', autoClose: 2000 });
        } else {
          toast.info('Me gusta eliminado', { position: 'top-center', autoClose: 2000 });
        }
      } else {
        toast.error('Error actualizando "Me gusta"');
      }
    } catch (error) {
      console.error('Error actualizando "Me gusta":', error);
      toast.error('Error actualizando "Me gusta"');
    }
  };

  const handleFavorite = async () => {
    try {
      const action = isFavorited ? 'remove' : 'add';
      const response = await api.post(`/assistants/${assistantId}/favorite`, { action });
      if (response.status === 200) {
        setIsFavorited(!isFavorited);
        if (!isFavorited) {
          toast.info('Asistente guardado como favorito', { position: 'top-center', autoClose: 2000 });
        } else {
          toast.info('Asistente eliminado de favoritos', { position: 'top-center', autoClose: 2000 });
        }
      } else {
        toast.error('Error actualizando favorito');
      }
    } catch (error) {
      console.error('Error actualizando favorito:', error);
      toast.error('Error actualizando favorito');
    }
  };

  const handleShare = () => {
    navigator.clipboard.writeText(window.location.href)
      .then(() => {
        toast.success('URL copiada');
      })
      .catch((err) => {
        console.error('Error copiando URL:', err);
        toast.error('Error copiando URL');
      });
  };

  const handleDrawerClose = () => {
    setIsLeftSidebarOpen(false);
    setIsRightSidebarOpen(false);
  };

  // Handle message like/dislike at message-level
  const toggleMessageLikeState = (index, newState) => {
    setChatMessages((prev) => {
      const updated = [...prev];
      const message = updated[index];
      if (message.sender !== 'assistant') return updated;

      if (message.likeState === newState) {
        message.likeState = 'none';
      } else {
        message.likeState = newState;
      }
      return updated;
    });
  };

  // File upload
  const handleFileUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      toast.info(`Archivo seleccionado: ${file.name}`);
      appendMessage('user', `Archivo subido: ${file.name}`);
      e.target.value = null;
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <SidebarCreateAsistente />

      <Drawer
        anchor="right"
        open={isRightSidebarOpen}
        onClose={handleRightSidebarToggle}
        sx={{
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: isMobile ? 224 : 280, overflowX: 'hidden' },
        }}
      >
        <Toolbar />
        <RightSidebar
          assistantName={assistantName}
          assistantDescription={assistantDescription}
          isMobile={true}
          assistantId={assistantId}
        />
      </Drawer>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: { xs: 1, sm: 2 },
          marginLeft: { sm: '60px' },
          marginRight: { sm: '60px' },
          mt: { xs: isMobile ? 7 : 0, sm: 0 },
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          position: 'relative',
        }}
      >
        <Snackbar
          open={errorMessage !== ''}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          TransitionComponent={Fade}
        >
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar>

        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          {/* Container for Assistant Avatar and User Camera */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '20px',
              position: 'fixed',
              top: { xs: '60px', sm: '20px' },
              left: '50%',
              transform: 'translateX(-50%)',
              zIndex: 1000,
            }}
          >
            {/* Assistant Avatar with green/blue borders */}
            <Box
              className="avatar-waves-wrapper"
              sx={{
                width: isMobile ? '160px' : '300px',
                height: isMobile ? '160px' : '300px',
                position: 'relative',
              }}
            >
              {/* Waves Container */}
              <Box className={`avatar-waves ${assistantStatus}`} />

              {/* Avatar Content */}
              <Box
                sx={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  borderRadius: '50%',
                  overflow: 'hidden',
                  zIndex: 3,
                }}
              >
                <VideoPlayer
                  videoUrl={videoUrl}
                  isTyping={isTyping}
                  placeholderImage={'https://via.placeholder.com/100'}
                  idExhUrl={IDExh}
                  onVideoEnd={() => setVideoUrl(IDExh)}
                />
              </Box>
            </Box>

            {/* User Camera Feed */}
            {cameraStream && (
                <Box
                  sx={{
                    width: isMobile ? '160px' : '300px',
                    height: isMobile ? '160px' : '300px',
                    borderRadius: '50%',
                    overflow: 'hidden',
                    boxShadow: '0px 2px 6px rgba(0,0,0,0.1)',
                    position: 'relative',
                    zIndex: 2,
                  }}
                >
                  <video
                    ref={cameraRef}
                    autoPlay
                    playsInline
                    muted
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                  />
                </Box>
              )}
          </Box>

          {/* Chat History */}
          <Box
            sx={{
              width: '100%',
              maxWidth: '800px',
              flexGrow: 1,
              overflowY: 'auto',
              paddingTop: isMobile ? '200px' : '360px',
              paddingBottom: '70px',
              margin: '0 auto',
            }}
          >
            {chatMessages.map((message, index) => (
              <Box
                key={index}
                className={message.sender === 'assistant' ? 'assistant-message' : ''}
                sx={{
                  display: 'flex',
                  justifyContent: message.sender === 'user' ? 'flex-end' : 'flex-start',
                  marginBottom: isMobile ? '7px' : '10px',
                }}
              >
                <Box
                  sx={{
                    maxWidth: isMobile ? '52.5%' : '60%',
                    bgcolor: message.sender === 'user' ? '#F1F3F4' : '#FFFFFF',
                    color: '#000000',
                    p: isMobile ? 2 : 3,
                    borderRadius: isMobile ? '16px' : '24px',
                    boxShadow: '0px 2px 6px rgba(0,0,0,0.1)',
                    fontSize: isMobile ? '0.7rem' : '1rem',
                    position: 'relative',
                  }}
                >
                  {message.text}
                  {message.sender === 'assistant' && (
                    <Box
                      sx={{
                        position: 'absolute',
                        bottom: '8px',
                        right: '8px',
                        display: 'flex',
                        gap: '4px',
                        alignItems: 'center',
                      }}
                    >
                      {message.video_url && (
                        <IconButton
                          aria-label="Download Video"
                          href={message.video_url}
                          download
                          sx={{ color: '#555555', padding: isMobile ? '1px' : '1px' }} // Adjusted padding
                        >
                          <DownloadIcon fontSize={isMobile ? 'small' : 'small'} /> {/* Responsive icon size */}
                        </IconButton>
                      )}
                      <IconButton
                        size="small"
                        color={message.likeState === 'like' ? 'primary' : 'default'}
                        onClick={() => toggleMessageLikeState(index, 'like')}
                        sx={{ padding: isMobile ? '1px' : '1px' }} // Adjusted padding
                      >
                        <ThumbUpIcon fontSize={isMobile ? 'small' : 'small'} />
                      </IconButton>
                      <IconButton
                        size="small"
                        color={message.likeState === 'dislike' ? 'error' : 'default'}
                        onClick={() => toggleMessageLikeState(index, 'dislike')}
                        sx={{ padding: isMobile ? '1px' : '1px' }} // Adjusted padding
                      >
                        <ThumbDownIcon fontSize={isMobile ? 'small' : 'small'} />
                      </IconButton>
                    </Box>
                  )}
                </Box>
              </Box>
            ))}
            {isTyping && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  marginBottom: isMobile ? '7px' : '10px',
                }}
              >
                <Box
                  sx={{
                    maxWidth: isMobile ? '52.5%' : '60%',
                    bgcolor: '#FFFFFF',
                    color: '#000000',
                    p: isMobile ? 2 : 3,
                    borderRadius: isMobile ? '16px' : '24px',
                    boxShadow: '0px 2px 6px rgba(0,0,0,0.1)',
                    fontSize: isMobile ? '0.7rem' : '1rem',
                  }}
                >
                  <Typography variant="body2">Escribiendo...</Typography>
                  <div className="loader-dots">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </Box>
              </Box>
            )}
            <div ref={chatEndRef} />
          </Box>

          {/* Like, Favorite, Share Buttons on Mobile */}
          {isMobile && (
            <Box
              sx={{
                position: 'fixed',
                bottom: '100px',
                left: '50%',
                transform: 'translateX(-50%)',
                width: '90%',
                maxWidth: '800px',
                display: 'flex',
                justifyContent: 'space-around',
                gap: '16px',
                padding: '8px 0',
                backgroundColor: '#ffffff',
                borderTop: '1px solid #e0e0e0',
                zIndex: 1000,
                boxShadow: '0px 0px 0px rgba(0,0,0,0.05)',
              }}
            >
              {/* Like Button */}
              <IconButton
                aria-label="Like"
                onClick={handleLike}
                sx={{
                  width: '48px',
                  height: '48px',
                  bgcolor: isLiked ? '#D0E8FF' : '#E5E7EB',
                  borderRadius: '50%',
                  color: '#333333',
                  boxShadow: '0px 2px 6px rgba(0,0,0,0.05)',
                  '&:hover': {
                    bgcolor: isLiked ? '#B0D8FF' : '#d1d5db',
                  },
                }}
              >
                <ThumbUpIcon fontSize="small" />
              </IconButton>

              {/* Favorite Button */}
              <IconButton
                aria-label="Favorite"
                onClick={handleFavorite}
                sx={{
                  width: '48px',
                  height: '48px',
                  bgcolor: isFavorited ? '#FFD1DC' : '#E5E7EB',
                  borderRadius: '50%',
                  color: '#333333',
                  boxShadow: '0px 2px 6px rgba(0,0,0,0.05)',
                  '&:hover': {
                    bgcolor: isFavorited ? '#FFC1CC' : '#d1d5db',
                  },
                }}
              >
                <FavoriteIcon fontSize="small" />
              </IconButton>

              {/* Share Button */}
              <IconButton
                aria-label="Share"
                onClick={handleShare}
                sx={{
                  width: '48px',
                  height: '48px',
                  bgcolor: '#E5E7EB',
                  borderRadius: '50%',
                  color: '#333333',
                  boxShadow: '0px 2px 6px rgba(0,0,0,0.05)',
                  '&:hover': {
                    bgcolor: '#d1d5db',
                  },
                }}
              >
                <ShareIcon fontSize="small" />
              </IconButton>
            </Box>
          )}

          {/* Fixed Text Input Box */}
          <Box
            sx={{
              position: 'fixed',
              bottom: isMobile ? '160px' : '20px',
              left: '50%',
              transform: 'translateX(-50%)',
              width: '98%',
              maxWidth: '800px',
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#ffffff',
              padding: isMobile ? '0px' : '0px',
              borderRadius: '40px',
              boxShadow: '0px 0px 0px rgba(0,0,0,0.1)',
              zIndex: 1000,
            }}
          >
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Escribe tu mensaje..."
              value={messageInput}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              disabled={isMessageInputDisabled}
              sx={{
                bgcolor: '#FFFFFF',
                borderRadius: '40px',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '40px',
                  paddingLeft: '20px',
                },
                fontSize: isMobile ? '0.7rem' : '1rem',
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton onClick={handleCameraInput} disabled={isMessageInputDisabled} size="small" className={cameraStream ? 'camera-active' : ''}>
                      <PhotoCameraIcon fontSize={isMobile ? 'small' : 'medium'} />
                    </IconButton>
                    <IconButton onClick={handleVoiceInput} disabled={isMessageInputDisabled} size="small">
                      <MicIcon fontSize={isMobile ? 'small' : 'medium'} />
                    </IconButton>
                    <IconButton onClick={handleFileUploadClick} disabled={isMessageInputDisabled} size="small">
                      <AttachFileIcon fontSize={isMobile ? 'small' : 'medium'} />
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={handleFileUpload}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={sendMessage} disabled={isMessageInputDisabled} size="small">
                      <SendIcon fontSize={isMobile ? 'small' : 'medium'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
      </Box>

      {!isMobile && (
        <RightSidebar
          assistantName={assistantName}
          assistantDescription={assistantDescription}
          isMobile={false}
          assistantId={assistantId}
        />
      )}

      {/* Eliminar el renderizado del LoginModal */}
      {/* {!isAuthenticated && (
      <LoginModal open={!isAuthenticated} onClose={() => {}} />
    )} */}
    </Box>
  );
};

export default Assistant;
